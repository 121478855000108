import React, {Component} from 'react';
import * as PropTypes from 'prop-types';
import {connect} from 'react-redux';
import queryString from 'query-string';
import {IntentionComponent, IntentionAddingIsCompleted, IntentionAddingIsFailed} from './../components/IntentionComponent';
import {intentionAdding} from './../actions/intention_adding';
import {intentionsGetting} from './../actions/intentions_getting';

class Intention extends Component {
  render() {
    const {t} = queryString.parse(window.location.search);
    const {sendIntention, isIntentionAdding, isIntentionCompleted, isIntentionFailed, getIntentions, intentions} = this.props;

    if(isIntentionCompleted) {
      return (
        <IntentionAddingIsCompleted />
      )
    }
    if(isIntentionFailed) {
      return (
        <IntentionAddingIsFailed />
      )
    }
    return (
      <IntentionComponent
        sendIntention={sendIntention}
        getIntentions={getIntentions}
        isIntentionAdding={isIntentionAdding}
        intentions={intentions}
        token={t}
        />
    )
  }
}

Intention.contextTypes = {
};

Intention.propTypes = {
  sendIntention: PropTypes.func,
  getIntentions: PropTypes.func,
  intentions: PropTypes.array,
  isIntentionAdding: PropTypes.bool,
  isIntentionCompleted: PropTypes.bool,
  isIntentionFailed: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    isIntentionAdding: state.intentionAddingReducer.status === 'intention_adding_start_in_progress',
    isIntentionCompleted: state.intentionAddingReducer.status === 'intention_adding_start_success',
    isIntentionFailed: state.intentionAddingReducer.status === 'intention_adding_start_failure',
    intentions: state.intentionsGettingReducer.intentions
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendIntention: content => {
      dispatch(intentionAdding(content))
    },
    getIntentions: datesRange => {
      dispatch(intentionsGetting(datesRange));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Intention);
