import React, {Component} from 'react';
import {Form, Input, TextArea, Button, Segment, Grid, Icon, Dropdown, Loader} from 'semantic-ui-react';
import {CalendarComponent} from './CalendarComponent';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/pl';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

export const IntentionAddingIsCompleted = () => (
  <Grid centered verticalAlign='middle' style={{height: '100vh'}}>
    <Grid.Column mobile={16} tablet={13} computer={13}>
      <Segment inverted color='green' textAlign='center' size='big'>
        Zarejestrowaliśmy Twoją intencję. Wkrótce wyślemy wiadomość z potwierdzeniem, na podany adres email.
      </Segment>
    </Grid.Column>
  </Grid>
);

export const IntentionAddingIsFailed = () => (
  <Grid centered verticalAlign='middle' style={{height: '100vh'}}>
    <Grid.Column mobile={16} tablet={13} computer={13}>
      <Segment inverted color='red' textAlign='center' size='big'>
        Nie dodano intencji mszalnej, błędny termin lub adres email.
      </Segment>
    </Grid.Column>
  </Grid>
);

export class IntentionComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: '',
      hour: '',
      email: '',
      content: '',
      options: [{
        key: 1, text: '19:00', value: '19:00:00'
      }],
      token: props.token
    }
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleChangeHour = this.handleChangeHour.bind(this);
  }

  handleChange = (event, {name, value}) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  }

  handleChangeDate(chosenDate) {
    const date = moment(chosenDate).format('YYYY-MM-DD');
    const hour = moment(chosenDate).format('HH:mm');
    const opt = this.props.intentions[date].filter(i => i.capacity > 0).map((cd, index) => ({
      key: index, text: moment(cd.date).format('HH:mm'), value: `${moment(cd.date).format('HH:mm')}:00`
    }));

    this.setState({
      date,
      hour: ' ' + hour,
      options: opt
    });
  }

  handleChangeHour(hour, {value}) {
    this.setState({
      hour: ' ' + value
    });
  }

  reset() {
    this.setState({
      date: '',
      email: '',
      content: ''
    });
  }

  componentDidMount() {
    this.props.getIntentions({startDate: moment(new Date()).subtract(7, 'days'), endDate: moment().add(350, 'days'), token: this.state.token})
  }

  render() {
    moment.locale('pl');
    const {date} = this.state;
    const {isIntentionAdding, intentions} = this.props;

    if (date) {
      return (
        <Grid centered verticalAlign='middle' style={{height: '100vh'}}>
          <Grid.Column mobile={20} tablet={13} computer={9}>
            <Segment stacked>
              <Form onSubmit={() => (this.props.sendIntention(this.state))}>
                <Form.Field required>
                  <label>Twój adres email:</label>
                  <Input
                    iconPosition='left'
                    placeholder='Email'
                    name="email"
                    onChange={this.handleChange}
                    required
                    >
                    <Icon name='at' />
                    <input />
                  </Input>
                </Form.Field>
                <Form.Field required>
                  <label>Wybrana data:</label>
                  <Input
                    placeholder='Data'
                    readOnly
                    value={this.state.date}
                    name="date"
                    onChange={this.handleChange}
                    required
                    />
                </Form.Field>
                <Form.Field required>
                  <label>Wybierz godzinę:</label>
                  <Dropdown
                    clearable
                    placeholder={this.state.hour}
                    options={this.state.options}
                    selection
                    required
                    onChange={this.handleChangeHour}
                    />
                </Form.Field>
                <Form.Field
                  required
                  control={TextArea}
                  maxlength="200"
                  label='Treść intencji - maksymalnie 200 znaków.'
                  placeholder='Treść intencji - maksymalnie 200 znaków.'
                  style={{ minHeight: 100 }}
                  name="content"
                  onChange={this.handleChange}
                  />
                <Button
                  disabled={isIntentionAdding}
                  content='Wróć'
                  onClick={() => (this.reset())}
                  />
                <Button
                  disabled={isIntentionAdding}
                  loading={isIntentionAdding}
                  type='submit'
                  content='Wyślij'
                  primary
                  floated='right'
                  />
              </Form>
            </Segment>
          </Grid.Column>
        </Grid>
      )
    } else if(intentions) {
      return (
        <CalendarComponent
          intentions={intentions}
          handleChangeDate={this.handleChangeDate}
        />
      );
    } else {
      return (
        <Loader active inline='centered' />
      )
    }
  }
};

IntentionComponent.propTypes = {
  sendIntention: PropTypes.func,
  getIntentions: PropTypes.func,
  intentions: PropTypes.array,
  token: PropTypes.string,
  isIntentionAdding: PropTypes.bool
};
