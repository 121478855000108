import React, {Component} from 'react';
import * as PropTypes from 'prop-types';
import is from 'is_js';
import {Form, Segment, Grid, Button, Loader, Dimmer, Modal, Table} from 'semantic-ui-react';

import {DayPickerSingleDateController, isInclusivelyAfterDay, VERTICAL_ORIENTATION} from 'react-dates';
import CustomizableCalendarDay, {defaultStyles} from 'react-dates/lib/components/CustomizableCalendarDay';

import moment from 'moment';
import 'moment/locale/pl';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

const customDayStylesGreen = {
  defaultStyles: {
    ...defaultStyles,
    background: '#019001',
    color: 'white',
    hover: {
      ...defaultStyles,
      color: 'white',
      fontWeight: 'bold',
      background: '#004a00',
    }
  }
};

const customDayStylesRed = {
  defaultStyles: {
    ...defaultStyles,
    background: '#c31700',
    color: 'white',
    hover: {
      ...defaultStyles,
      color: 'white',
      fontWeight: 'bold',
      background: '#8a0000',
    }
  }
};

const customDayStylesDisabled = {
  defaultStyles: {
    ...defaultStyles,
    color: '#cacaca',
    hover: {
      ...defaultStyles,
      color: '#7d7d7d',
      fontWeight: 'bold'
    }
  }
};

export class CalendarComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chosenDate: ''
    }

    this.isDayAvailable = this.isDayAvailable.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
  }

  handleChangeDate(date) {
    const chosenDate = moment(date).format('YYYY-MM-DD');

    // const filteredIntentions = this.props.intentions[dayFormated].map(
    //   df =>
    //   {
    //       if(df.capacity > 0) {
    //         return {
    //           date: moment(df.date).format('DD-MM-YYYY'),
    //           hour: moment(df.date).format('HH:mm'),
    //           isFree: true,
    //           intention: df.intentions
    //         };
    //       } else {
    //         return {
    //           date: moment(df.date).format('DD-MM-YYYY'),
    //           hour: moment(df.date).format('HH:mm'),
    //           isFree: false,
    //           intention: df.intentions
    //         };
    //       }
    //     }
    // );

    this.setState({
      chosenDate
    });
  }

  removeChosenDate() {
    this.setState({
      chosenDate: ''
    });
  }

  isDayAvailable(day, capacityArray) {
    const getMonth = moment(day.day).format('YYYY-MM-DD');

    if(moment(day.day) <= moment(new Date())) {
      return <CustomizableCalendarDay
          {...day}
          {...customDayStylesDisabled}
      />;
    }

    if(!capacityArray[getMonth]) {
      return <CustomizableCalendarDay
      {...day}
      />;
    }

    const comp = capacityArray[getMonth]
    .map(df => {
       if(df.capacity > 0) {
         return 1;
         } else {
         return 0;
       }
    });

    if (comp.length !== 0) {
      const sum = comp.reduce((d, a) => d + a);
      if(sum === 0) {
        return <CustomizableCalendarDay
        {...day}
        {...customDayStylesRed}
        />
    } else {
        return <CustomizableCalendarDay
        {...day}
        {...customDayStylesGreen}
        />
    }
    } else {
      return <CustomizableCalendarDay
      {...day}
      />
    }
  }

  renderCalendar(len, props){
    let cal = [];
    for (let i = 0; i < 12; i += len) {
        cal.push(<DayPickerSingleDateController
          key
          {...props}
          initialVisibleMonth={() => moment().add(i, 'months')}
          focused={true}
          renderCalendarDay={day => this.isDayAvailable(day, this.props.intentions)}
          onDateChange={this.handleChangeDate}
        />);
    }
    return cal;
  }

  checkDayPropriety(day) {
    return moment(day) <= moment(new Date()).subtract(7, "days");
  }

  render() {
    const defaultProps = {
      // example props for the demo
      autoFocus: false,
      // initialDate: null,
      showInput: false,

      // day presentation and interaction related props
      // renderCalendarDay: day => this.isDayAvailable(day, this.props.capacity),
      renderDayContents: null,
      isDayBlocked: () => false,
      isOutsideRange: day => this.checkDayPropriety(day),
      isDayHighlighted: () => false,
      enableOutsideDays: false,

      // calendar presentation and interaction related props
      // orientation: VERTICAL_ORIENTATION,
      // withPortal: false,
      // initialVisibleMonth: null,
      numberOfMonths: is.desktop() ? 3 : 1,
      onOutsideClick() {},
      keepOpenOnDateSelect: false,
      renderCalendarInfo: null,
      isRTL: false,

      // navigation related props
      navPrev: <div disabled/>,
      navNext: <div disabled/>,
      onPrevMonthClick() {},
      onNextMonthClick() {},
      hideKeyboardShortcutsPanel: true,

      dayPickerHorizontalPadding: 50,

      // internationalization
      monthFormat: 'MMMM YYYY',
    };

  const calendarConf = is.desktop() ? 3 : 1;

  return (
    <Grid centered verticalAlign='middle' style={{height: '100vh'}}>
      <Grid.Column mobile={16} tablet={16} computer={12}>
        <Segment placeholder textAlign='center'>
          <Dimmer active={this.state.loaderActive}>
            <Loader content='Loading' />
          </Dimmer>
          <h2>Intencje mszalne</h2>
          <Form>
            {this.renderCalendar(calendarConf, defaultProps)}
          </Form>
        </Segment>
      </Grid.Column>
      {this.state.chosenDate !== '' ?
        <Modal open={this.state.chosenDate !== ''} onClose={() => this.removeChosenDate()} closeIcon>
          <Modal.Header>
            Wybrana data: {this.state.chosenDate}
          </Modal.Header>
          <Modal.Content>
          {this.props.intentions[this.state.chosenDate] ? this.props.intentions[this.state.chosenDate].map(cd =>
            <Table celled structured>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell textAlign='center'>
                    {moment(cd.date).format('HH:mm')}
                  </Table.HeaderCell>
                  {((cd.capacity > 0) && (moment(this.state.chosenDate) > moment(new Date()))) ?
                    <Table.HeaderCell width={3}>
                      <Button
                        primary
                        onClick={() => this.props.handleChangeDate(cd.date)}> Zamów mszę </Button>
                    </Table.HeaderCell> : null}
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell colSpan='2'>Treść</Table.HeaderCell>
                </Table.Row>

              </Table.Header>
              {cd.intentions.map(i =>
                <Table.Row>
                  <Table.Cell colSpan='2'>
                    {i.content}
                  </Table.Cell>
                </Table.Row>)
                }
              </Table>): "Brak Mszy Świętych w tym terminie."}
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="red"
              onClick={() => this.removeChosenDate()}> Zamknij </Button>
          </Modal.Actions>
        </Modal>
        : null}
    </Grid>
  );
  }
}

CalendarComponent.propTypes = {
  capacity: PropTypes.array,
  intentions: PropTypes.array,
  handleChangeDate: PropTypes.func
};
