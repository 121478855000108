import React, { Component } from 'react';
import Intention from './containers/IntentionContainer';
import './App.css';

class App extends Component {
  render() {
    return (
      <Intention/>
    );
  }
}

export default App;
