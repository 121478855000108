import {
  INTENTION_GETTING_START,
  INTENTION_GETTING_IN_PROGRESS,
  INTENTION_GETTING_SUCCESS,
  INTENTION_GETTING_FAILURE
} from './../constants/intentions_getting_types';

import axios from 'axios';

console.log(process.env.REACT_APP_INTENTIONS_BACKEND);

const apiProvider = axios.create({
  baseURL: process.env.REACT_APP_INTENTIONS_BACKEND,
  timeout: 180000
});

export function intentionsGettingStart() {
  return {
    type: INTENTION_GETTING_START,
    payload: {}
  };
}

export function intentionsGettingInProgress() {
  return {
    type: INTENTION_GETTING_IN_PROGRESS,
    payload: {}
  };
}

export function intentionsGettingSuccess(intentions) {
  return {
    type: INTENTION_GETTING_SUCCESS,
    payload: intentions
  };
}

export function intentionsGettingFailure(error) {
  return {
    type: INTENTION_GETTING_FAILURE,
    payload: error
  };
}

export function intentionsGetting(datesRange) {
  return dispatch => {
    dispatch(intentionsGettingStart());

    const formData = new FormData();
    formData.append('start_date', datesRange.startDate);
    formData.append('end_date', datesRange.endDate);

    const req = apiProvider.post('/api/v1/get-intentions-capacity', formData, {
      headers: {
        Authorization: datesRange.token
      }
    });

    dispatch(intentionsGettingInProgress());

    req.then(res => {
      dispatch(intentionsGettingSuccess(res.data.intentions));
    }).catch(err => {
      console.error(err);
      dispatch(intentionsGettingFailure(err));
    });
  }
}
