import {
  INTENTION_ADDING_START,
  INTENTION_ADDING_IN_PROGRESS,
  INTENTION_ADDING_SUCCESS,
  INTENTION_ADDING_FAILURE
} from './../constants/intention_adding_types';

import axios from 'axios';
import {intentionsGetting} from './intentions_getting';

import moment from 'moment';
import 'moment/locale/pl';

const apiProvider = axios.create({
  baseURL: process.env.REACT_APP_INTENTIONS_BACKEND,
  timeout: 180000
});

export function intentionAddingStart() {
  return {
    type: INTENTION_ADDING_START,
    payload: {}
  };
}

export function intentionAddingInProgress() {
  return {
    type: INTENTION_ADDING_IN_PROGRESS,
    payload: {}
  };
}

export function intentionAddingSuccess() {
  return {
    type: INTENTION_ADDING_SUCCESS,
    payload: {}
  };
}

export function intentionAddingFailure(error) {
  return {
    type: INTENTION_ADDING_FAILURE,
    payload: error
  };
}

export function intentionAdding(intetnion) {
  return dispatch => {
    dispatch(intentionAddingStart());

    console.log(intetnion);

    const formData = new FormData();
    formData.append('email', intetnion.email);
    formData.append('content', intetnion.content);
    formData.append('date', intetnion.date + intetnion.hour);

    const req = apiProvider.post('/api/v1/create-intention', formData, {
      headers: {
        Authorization: intetnion.token
      }
    });

    dispatch(intentionAddingInProgress());

    req.then(res => {
      setTimeout(
        () => {
          dispatch(intentionAddingStart());
          dispatch(intentionsGetting({startDate: moment(new Date()), endDate: moment().add(350, 'days'), token: intetnion.token}));
        }, 2000
      );
      dispatch(intentionAddingSuccess());
    }).catch(err => {
      console.error(err);
      setTimeout(
        () => {
          dispatch(intentionAddingStart());
          dispatch(intentionsGetting({startDate: moment(new Date()), endDate: moment().add(350, 'days'), token: intetnion.token}));
        }, 2000
      );
      dispatch(intentionAddingFailure(err));
    });
  }
}
