import {
  INTENTION_ADDING_START,
  INTENTION_ADDING_IN_PROGRESS,
  INTENTION_ADDING_SUCCESS,
  INTENTION_ADDING_FAILURE
} from './../constants/intention_adding_types';

const initialState =
    {
      status: 'intention_adding_start',
      error: ''
    };

export default (state = initialState, action) => {
 switch (action.type) {
  case INTENTION_ADDING_START:
   return initialState

  case INTENTION_ADDING_IN_PROGRESS:
    return {...state, status: 'intention_adding_start_in_progress', error: ''};

  case INTENTION_ADDING_SUCCESS:
    return {...state, status: 'intention_adding_start_success', error: ''};

  case INTENTION_ADDING_FAILURE:
    return {...state, status: 'intention_adding_start_failure', error: action.payload};

  default:
   return state
 }
}
