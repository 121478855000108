import {
  INTENTION_GETTING_START,
  INTENTION_GETTING_IN_PROGRESS,
  INTENTION_GETTING_SUCCESS,
  INTENTION_GETTING_FAILURE
} from './../constants/intentions_getting_types';

const initialState =
    {
      status: 'intentions_getting_start',
      intentions: '',
      error: ''
    };

export default (state = initialState, action) => {
 switch (action.type) {
  case INTENTION_GETTING_START:
   return initialState

  case INTENTION_GETTING_IN_PROGRESS:
    return {...state, status: 'intentions_getting_start_in_progress', intentions: '', error: ''};

  case INTENTION_GETTING_SUCCESS:
    return {...state, status: 'intentions_getting_start_success', intentions: action.payload, error: ''};

  case INTENTION_GETTING_FAILURE:
    return {...state, status: 'intentions_getting_start_failure', intentions: '', error: action.payload};

  default:
   return state
 }
}
